import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {agentDeveloperUtils} from 'wobi-web-common';
import TabsLayout from '../components/TabsLayout';
import stepsTabs from '../config/tabs';
import {getCurrentTabIndex, tabIndexToName} from '../utils/routingHelpers';
import OtpContainer from '../components/OtpContainer';
import {getOffersFields, setOffersFields} from '../utils/persistOfferDetails';
import {fireGtmEvent} from '../utils/marketing.utils';
import {getLatestLeadByPhoneNumber} from '../utils/apiHandlers';
import {autoInfoValidations} from './tabs-contents/details/AutoInfo';
import {driversTabValidation} from './tabs-contents/details/Drivers';
import {insuranceHistoryValidations} from './tabs-contents/details/InsuranceHistory';
import {AutoInfoTab, DriversTab, InsuranceHistoryTab} from './tabs-contents/details';
import {detailInitialState} from './tabs-contents/constants';

const tabs = stepsTabs.details;

class InitialDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.setTab = this.setTab.bind(this);
    this.handleOtpVerified = this.handleOtpVerified.bind(this);

    this.detailsStepState = detailInitialState();
  }

  state = {
    isOtpFirst: false,
    maxIndex: 0,
    otpOpen: false,
    tab: 0,
  };

  componentDidMount() {
    if (agentDeveloperUtils.isOtpFirstInOffersFields()) {
      this.handleOtpFirstOnMount();
    }
    this.detailsStepState = {...this.detailsStepState, ...getOffersFields()};
  }

  componentWillMount() {
    this.setTab(this.props.location);
    this.unlisten = this.props.history.listen((location) => {
      this.setTab(location);
    });
  }

  handleOtpFirstOnMount() {
    this.handleOtpOpen();
    this.setState({isOtpFirst: true});
    setOffersFields({otp: ''});
  }

  handleOtpFirstOnOtpVerified() {
    // eslint-disable-next-line max-len
    const agentDeveloperName = agentDeveloperUtils.getAgentDeveloperName === 'Wobi' ? '' : agentDeveloperUtils.getAgentDeveloperName;
    const requestData = {
      agentDeveloperName,
      serviceType: agentDeveloperUtils.serviceType,
      token: getOffersFields('token'),
    };
    getLatestLeadByPhoneNumber(requestData).then(resp => {
      setOffersFields(resp?.data);
      this.props.history.push('/getting-started');
      setOffersFields({offersCallId: 'tmp'});
      this.setState({isOtpFirst: false});
    }).catch((error) => console.error('Failed to fetch lead data', error));
  }

  handleChange(tabIndex) {
    if (tabIndex === this.state.tab) {
      return;
    }
    const tabName = tabIndexToName(tabIndex, 'details');
    this.props.history.push(`#${tabName}`);
  }

  setTab(location) {
    const tabIndex = getCurrentTabIndex(location, 'details');
    const maxIndex = sessionStorage.getItem('maxStepThatComplete') || null;
    if (Boolean(maxIndex) && tabIndex > maxIndex) {
      sessionStorage.setItem('maxStepThatComplete', tabIndex);
      this.setState({maxIndex: Number(tabIndex)});
    } else if (maxIndex) {
      this.setState({maxIndex: Number(maxIndex)});
    } else {
      sessionStorage.setItem('maxStepThatComplete', 0);
      this.setState({maxIndex: 0});
    }

    if (tabIndex === false) {
      return;
    }
    this.setState({tab: tabIndex});
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleOtpVerified() {
    if (this.state.isOtpFirst) {
      this.handleOtpFirstOnOtpVerified();
    } else {
      this.props.history.push('/offers');
    }
  }

  handleOtpBlocked() {
    console.log('redirect to OverUse');
    this.props.history.push('/overuse');
  }

  handleOtpOpen = () => {
    if (this.state.isOtpFirst) {
      this.handleOtpVerified();
    }
    if (getOffersFields('phone') && getOffersFields('token') && getOffersFields('offersCallId')) {
      this.handleOtpVerified();
    } else {
      this.setState({otpOpen: true});
    }
  };

  render() {
    return (
      <Formik
        validationSchema={yup.object().shape({
          ...autoInfoValidations,
          ...driversTabValidation,
          ...insuranceHistoryValidations,
        })}
        initialValues={this.detailsStepState}>
        {({values, errors, touched, handleChange, handleBlur, handleSubmit,
          isSubmitting, setFieldValue, validateForm, resetForm, setFieldTouched}) => (
          <Form>
            <TabsLayout
              tabs={tabs}
              currentTabIndex={this.state.tab}
              maxIndex={this.state.maxIndex}
              formikHandleSubmit={handleSubmit}
              formikValidateFrom={validateForm}
              formikHandleChange={handleChange}
              formikSetField={setFieldValue}
              formikSetTouched={setFieldTouched}
              formikHandleBlur={handleBlur}
              formikErrors={errors}
              formikResetForm={resetForm}
              formikValues={values}
              formikIsSubmitting={isSubmitting}
              formikTouched={touched}
              handleChange={(event, tabIndex) => {
                this.handleChange(tabIndex);
                fireGtmEvent('MenuAutoDetails', {tabIndex});
              }}
            >
              <AutoInfoTab/>
              <DriversTab/>
              <InsuranceHistoryTab handleOtpOpen={this.handleOtpOpen}/>
            </TabsLayout>
            <OtpContainer open={this.state.otpOpen}
              handleVerified={() => this.handleOtpVerified()}
              handleBlocked={() => this.handleOtpBlocked()}
              handleClose={() => this.setState({otpOpen: false})}
              showNID />
          </Form>
        )}
      </Formik>

    );
  }
}

InitialDetailsPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(InitialDetailsPage);
